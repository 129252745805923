<template>
    <div class="container " style="height:100%">

        <div class="row flex-grow-0">
            <div class="col col-md-4">
                <label class="col" for="commonCode">
                    <span>{{ $t('공통 코드') }}</span>
                    <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
                </label>
                <div class="col">
                    <input v-model="detailInfo.commonCode" type="text" :disabled="!isNewMode" class="form-control"
                        id="commonCode" placeholder="공통 코드">
                </div>
            </div>
            <div class="col col-md-4">
                <label class="col" for="codeName">
                    <span>{{ $t('공통 코드명') }}</span>
                    <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
                </label>
                <div class="col">
                    <input v-model="detailInfo.codeName" type="text" :disabled="!isEditMode" class="form-control"
                        id="codeName" placeholder="코드명">
                </div>
            </div>
            <div class="col col-md-2">
                <label class="col" for="codeCategory">
                    <span>{{ $t('카테고리') }}</span>
                    <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
                </label>
                <div class="col">
                    <input v-model="detailInfo.codeCategory" type="text" :disabled="!isEditMode" class="form-control"
                        id="codeCategory" placeholder="카테고리">
                </div>
            </div>
            <div class="col col-md-2">
                <label class="col" for="enabled">
                    <span>{{ $t('사용여부') }}</span>
                    <!-- <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i> -->
                </label>
                <div class="col">
                    <select v-model="detailInfo.enabled" :disabled="!isEditMode" id="enabled" class="form-control">
                        <option value="">선택</option>
                        <option v-for="code in $store.state.commonCodes.enabled" :key="code.value" :value="code.value">
                            {{ code.text }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row flex-grow-0">
            <div class="col-md-1" style="text-align: center;">
                <label for="equipDesc">설명</label>
            </div>
            <div class="col-md-11">
                <textarea v-model="detailInfo.desc" type="text" class="form-control" id="equipDesc"
                    :disabled="!isEditMode" style="resize: none; overflow-y: auto;" rows="3"></textarea>
            </div>
        </div>

        <div class="row flex-grow-1 mx-1 mt-2 " style="height: 65%;">
            <!-- TableList -->
            <TableListComponent ref="TableListComponent" :title="'코드 항목'" :columns="columns"
                :rows="detailInfo.codeItems" :keyField="'codeItem'" :transCodes="transCodes" :controls="controls"
                :mode="mode" :useMaxHeight="true" :toggleFields="['enabled']" @button-click="handleListButtonClick"
                :useFilter="false" @row-click="handleListItemClick" @toggle-changed="handleItemToggle" />
        </div>

        <!-- Detail PropUp -->
        <div class="row flex-grow-0">
            <!-- InPropDetail Popup -->
            <b-modal ref="CodeItemDetailModal" size="md" centered @hidden="closeCodeItemModal">
                <template #modal-header>
                    <h5>{{ tempCodeItemIndex !== null || undefined ? '코드 편집' : '코드 추가' }}</h5>
                </template>
                <template #modal-footer centered>
                    <button v-show="isEditMode" type="button" class="btn btn-primary" @click="handleAddListItem"
                        style="flex: 1;">저장</button>
                    <button type="button" class="btn btn-secondary" @click="closeCodeItemModal"
                        style="flex: 1;">닫기</button>
                </template>
                <CodeItemDetailPanel ref="CodeItemDetailPanel" :isEditMode="isEditMode" :data="detailInfo" />
            </b-modal>
        </div>

    </div>
</template>

<script>
// import backEndApi from "@src/api/backEndApi";

import TableListComponent from "@views/component/v2.1/ListDetailView/TableList.vue";
import CodeItemDetailPanel from "./CodeItemDetailPanel.vue"

export default {
    components: {
        TableListComponent,
        CodeItemDetailPanel
    },
    props: ['detailInfo', 'mode'],
    data() {

        return {
            controls: [
                { event: 'delete', label: '-', class: 'btn-danger' },
                { event: 'regist', label: '+', class: 'btn-warning' },
            ],
            transCodes: [
                {
                    field: 'enabled',
                    codes: this.$store.state.commonCodes.enabled,
                },
            ],
            columns: [ // Detail 테이블 column
                {
                    label: this.$t("코드 항목"),
                    field: "codeItem",
                    type: "text",
                    thClass: 'text-center',
                    tdClass: 'text-center font-weight-bold text-dark',
                    // compositionFields: ['codeItem', 'codeName']
                },
                {
                    label: this.$t("코드명"),
                    field: "codeName",
                    type: "text",
                    thClass: 'text-center',
                    tdClass: 'text-center font-weight-bold  text-dark',
                    // compositionFields: ['codeItem', 'codeName']
                },
                {
                    label: this.$t("속성1"),
                    field: "etcInfo1",
                    type: "text",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: this.$t("속성2"),
                    field: "etcInfo2",
                    type: "text",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: this.$t("속성3"),
                    field: "etcInfo3",
                    type: "text",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: this.$t("속성4"),
                    field: "etcInfo4",
                    type: "text",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: this.$t("속성5"),
                    field: "etcInfo5",
                    type: "text",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: this.$t("사용 여부"),
                    field: "enabled",
                    type: "text",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    width: "80px",
                },
            ],
            tempCodeItemIndex: null,
        }
    },
    async created() {
    },
    mounted() {
        // this.setDetailVgtHeader();
    },
    computed: {
        isEditMode() {
            return this.mode !== 'view';
        },
        isNewMode() {
            return this.mode === 'new';
        },
        
    },
    watch: {
        detailInfo() {
            this.setDetailVgtHeader();
        },
        'isEditMode': 'formattedDesc',
    },
    methods: {
        formattedDesc() {
            if (this.isEditMode && !this.detailInfo.desc?.trim().length) {
                let codeItemText = this.detailInfo.codeItems.filter(item => item.enabled == 'Y')
                                                            .map(item => (`${item.codeName}(${item.codeItem})`)).join(',');
                let descText = `${this.detailInfo.codeName}(${this.detailInfo.commonCode}): ${codeItemText}`;
                this.detailInfo.desc = descText;
            }
        },
        async handleListButtonClick(event, options) {
            const that = this;


            switch (event) {
                case 'regist':
                    await that.registNew();
                    break;

                case 'delete':
                    await that.deleteChecked();
                    break;

                default:
                    console.warn('Unhandled Component Event. event:', event, 'options:', options);
                    break;
            }
        },
        async handleListItemClick(row, index, toggle, check) {
            const that = this;
            if (toggle || check) return;
            that.tempCodeItemIndex = index;
            const findCodeItem = this.detailInfo.codeItems.find(item => item.codeItem == row.codeItem)
            await that.$refs.CodeItemDetailModal.show();
            await that.$refs.CodeItemDetailPanel.setData(findCodeItem);
        },
        closeCodeItemModal() {
            const that = this;
            if (that.tempCodeItemIndex !== null || undefined) that.tempCodeItemIndex = null;
            that.$refs.CodeItemDetailModal.hide()
        },
        async deleteChecked() {
            const that = this;

            try {
                const checked = this.$refs['TableListComponent'].getCheckedRows();

                if (!that.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                    if (!confirm.value) return;
                    checked.forEach(async () => {
                        // detailInfo에서 checked 항목을 제외.
                        that.detailInfo.codeItems = that.detailInfo.codeItems.filter(item => {
                            return !checked.find(checkedProp => checkedProp.codeItem === item.codeItem);
                        })
                    });
                    // await this.getDataList();
                } else {
                    this.alertNoti("목록을 선택해주세요.")
                }
            } catch (e) {
                this.alertNoti("삭제에 실패하였습니다.");
                console.error(e)
            }
        },
        async registNew() {
            const that = this;

            await that.$refs.CodeItemDetailModal.show();
            await that.$refs.CodeItemDetailPanel.setData(null);
        },
        handleAddListItem() {
            const codeItem = this.$refs.CodeItemDetailPanel.getData();

            if (codeItem) {
                if (this.tempCodeItemIndex !== null || undefined) {
                    this.$set(this.detailInfo.codeItems, this.tempCodeItemIndex, codeItem);
                } else {
                    let check = this.detailInfo.codeItems.find(item => item.codeItem === codeItem.codeItem || item.codeName === codeItem.codeName);
                    if (check) {
                        // TODO: Alert Message
                        console.error('TODO: Alert Message');
                        console.error(`In Property is already exists in codeItems. codeItem:${codeItem.codeItem}, codeName:${codeItem.codeName}`);
                    } else {
                        this.$set(this.detailInfo.codeItems, this.detailInfo.codeItems.length, codeItem);
                    }
                }
            }


            this.$refs.CodeItemDetailModal.hide();
        },
        handleItemToggle(key, field, status) {

            const that = this;
            const found = that.detailInfo.codeItems.find(item => item.codeItem === key)
            found.enabled = status
        },
        setDetailVgtHeader() {
            if (this.detailInfo) {
                this.columns.map((item, index) => {
                    Object.keys(this.detailInfo).forEach((key) => {
                        if (key == item.field && item.field !== 'enabled') {
                            this.$set(item, "label", this.detailInfo[key] ? this.detailInfo[key] : `속성${index}`)
                        }
                    })
                })
            }
        },
    }

}
</script>

<style scoped>

.row {
    margin-bottom: 16px;
}

.col {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
}
</style>